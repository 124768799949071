<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="mappedBookmarks(role)" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import { mapGetters } from 'vuex'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  computed: {
    ...mapGetters({
      user: 'oidc/oidcUser',
      role: 'users/getUserRole',
    }),
  },
  methods: {
    mappedBookmarks(role) {
      const auth = {
        'LeRefuge.Administrateur': ['Statistiques', 'Utilisateurs'],
        'LeRefuge.Coordinateur': ['Planning', 'Jeunes', 'Répondeur', 'Statistiques', 'Utilisateurs', 'Blocklist'],
        'LeRefuge.Ecoutant': ['Planning', 'Jeunes', 'Répondeur', 'Blocklist'],
      }
      return this.navMenuItems.filter(bookmark => auth[role]?.includes(bookmark.title))
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
